import React, { useState } from 'react'
import { useQueryParam, StringParam } from "use-query-params";
import { Composition } from 'atomic-layout'
// import { Text, Button, Image } from '../../atoms'
import { Flex,Input as MyInput, Label, Text, Box } from 'theme-ui';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import MyButton from './Button';
import HypefastLogo from "../assets/hypefast.svg";
import { useMutation } from 'react-query';
import api from '../utils/api';
import { navigate } from 'gatsby';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import IconButton from './Editor/IconButton';

const areasMobile = `
  header
  name
  password
  passwordConfirmation
  button
  footer
`

const schema: Yup.SchemaOf<ResetPasswordData> = Yup.object({
  name: Yup.string().required('Nama wajib diisi!'),
  password: Yup.string().required('Password wajib diisi!'),
  passwordConfirmation: Yup.string().required('Password wajib diisi!')
    .oneOf([Yup.ref('password')], 'Password tidak sesuai!'),
});

const AcceptInvitation = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [token] = useQueryParam("invitation_token", StringParam);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSuccess = () => {
    navigate('/');
  };

  const onError = (error: any) => {
    if (error) {
      console.log(error);
      // const [message] = (error as ErrorResponse).messages;
      // setErrroMessage(message);
      // const email = document.querySelector('[name="email"]') as HTMLInputElement;
      // email?.focus();
    }
  };
  
  const {
    isLoading,
    mutate,
  } = useMutation(({ name, password, passwordConfirmation }) => api.acceptInvitation({
    invitationToken: token,
    name,
    password,
    passwordConfirmation,
  }), { onSuccess, onError });

  return (
    <Flex
      sx={{
        backgroundColor: '#f8f8f8',
        height: '100vh',
        alignItems: 'center',
        px: 5,
      }}
    >
      <Composition
        width={'100%'}
        inline
        // as={Container}
        areas={areasMobile}
        templateCols={'1fr'}
        gap={20}
        padding={32}
      >
        {({ Header, Name, Password, PasswordConfirmation, Button, Footer }) => (
          <>
            <Header>
              <Box
                py={4}
              >
                <HypefastLogo
                  width={'100%'}
                />
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='headline'
                >
                  Awesome!
                </Text>
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='body'
                >
                  Complete the following form to accept the invitation!
                </Text>
              </Box>
            </Header>
            <Name>
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Name
                  </Text>
                </Label>
              </Box>
              <MyInput
                sx={{ width: '96%' }}
                // sx={{
                //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                //   backgroundColor: '#d7cd74',
                // }}
                // placeholder={errors.password?.type === 'required' && "Password wajib diisi"}
                variant={errors.name && 'error' || 'input'}
                {...register("name")}
              />
              {errors.name && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.name.message}</Text>
              )}
            </Name>
            <Password>
              {/* <InputComponent
                isPassword
                isInvalid={!!errors.password}
                label="Password"
                errorMessage={errors?.password?.message}
                {...register("password")}
              /> */}
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Password
                  </Text>
                </Label>
              </Box>
              <Flex>
                <MyInput
                  // sx={{
                  //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                  //   backgroundColor: '#d7cd74',
                  // }}
                  // placeholder={errors.password?.type === 'required' && "Password wajib diisi"}
                  variant={errors.password && 'error' || 'password'}
                  type={showPassword ? 'text' : 'password'}
                  {...register("password")}
                />
                <IconButton
                  icon={showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'relative',
                    right: '30px'
                  }}
                />
              </Flex>
              {errors.password && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.password.message}</Text>
              )}
            </Password>
            <PasswordConfirmation>
              {/* <InputComponent
                isPassword
                isInvalid={!!errors.passwordConfirmation}
                label="Password Confirmation"
                errorMessage={errors?.passwordConfirmation?.message}
                {...register("passwordConfirmation")}
              /> */}
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Password Confirmation
                  </Text>
                </Label>
              </Box>
              <Flex>
                <MyInput
                  // sx={{
                  //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                  //   backgroundColor: '#d7cd74',
                  // }}
                  // placeholder={errors.password?.type === 'required' && "Password wajib diisi"}
                  variant={errors.passwordConfirmation && 'error' || 'password'}
                  type={showPass ? 'text' : 'password'}
                  {...register("passwordConfirmation")}
                />
                <IconButton
                  icon={showPass ? <FaRegEyeSlash /> : <FaRegEye />}
                  onClick={() => setShowPass(!showPass)}
                  style={{
                    position: 'relative',
                    right: '30px'
                  }}
                />
              </Flex>
              {errors.passwordConfirmation && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.passwordConfirmation.message}</Text>
              )}
            </PasswordConfirmation>
            <Button>
              <MyButton
                full
                isLoading={isLoading}
                variant='primary'
                sx={{
                  // backgroundColor: '#332f11',
                }}
                onClick={handleSubmit(mutate)}
              >
                <Text
                  variant='subtitle'
                  // sx={{
                  //   color: '#ffe07c'
                  // }}
                >
                  Accept Invitation
                </Text>
              </MyButton>
            </Button>
            <Footer>
              {/* <Button variant='primary'>Buy</Button> */}
            </Footer>
          </>
        )}
      </Composition>

    </Flex>
  )
}

export default AcceptInvitation
