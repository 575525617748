import * as React from 'react'
import AcceptInvitation from '../components/AcceptInvitation'
import Page from '../components/Layout/Page';


// markup
const AcceptInvitationPage = () => {
  return (
    <Page>
      <AcceptInvitation/>
    </Page>
  )
}

export default AcceptInvitationPage
